<template>
  <div class="pagination">
    <md-button
      class="md-raised md-primary"
      :disabled="currentPage == 1"
      @click="changePage(currentPage - 1)"
    >
      Назад
    </md-button>

    <md-field>
      <md-select md-dense :value="currentPage" @md-selected="changePage">
        <md-option v-for="i in totalPages" :key="`page_${i}`" :value="i">
          {{ i }}
        </md-option>
      </md-select>
    </md-field>

    <md-button
      class="md-raised md-primary"
      :disabled="currentPage == totalPages"
      @click="changePage(currentPage + 1)"
    >
      Вперед
    </md-button>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },

    totalPages: {
      type: Number,
      default: 1,
    },
  },

  methods: {
    changePage(page) {
      this.$emit("change-page", page);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  .md-field {
    width: 100px;
    margin: 4px 15px 24px;
  }
}
</style>